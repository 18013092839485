
import { Options, Vue } from 'vue-class-component'
import axios from '@/plugins/axios'
import SyncErrorsOrderTable from '../components/SyncErrorsOrderTable.vue'
import SyncErrorsProductTable from '../components/SyncErrorsProductTable.vue'
import SyncErrorsShipmentTable from '../components/SyncErrorsShipmentTable.vue'
import SyncErrorsRefundTable from '../components/SyncErrorsRefundTable.vue'
import SyncErrorsOrderListMobile from '../components/SyncErrorsOrderListMobile.vue'
import SyncErrorsProductListMobile from '../components/SyncErrorsProductListMobile.vue'
import SyncErrorsShipmentListMobile from '../components/SyncErrorsShipmentListMobile.vue'
import SyncErrorsRefundListMobile from '../components/SyncErrorsRefundListMobile.vue'

interface Result {
  total: number
  list: []
  data: []
}

@Options({
  components: {
    SyncErrorsOrderTable,
    SyncErrorsProductTable,
    SyncErrorsShipmentTable,
    SyncErrorsRefundTable,
    SyncErrorsOrderListMobile,
    SyncErrorsProductListMobile,
    SyncErrorsShipmentListMobile,
    SyncErrorsRefundListMobile
  }
})
export default class SyncErrorsPage extends Vue {
  orderSyncErrorList: any = []
  shipmentSyncErrorList: any = []
  refundSyncErrorList: any = []
  productSyncErrorList: any = []
  currentOrderPage = 1
  currentShipmentPage = 1
  currentRefundPage = 1
  currentProductPage = 1
  totalOrderRows = 0
  totalShipmentRows = 0
  totalRefundRows = 0
  totalProductRows = 0
  perPage = 20
  isShowWarning = true
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  selectedType = 'Product'

  condition = {
    completed: undefined
  }
  statusList: any = [
    { label: 'All', value: undefined },
    { label: 'Completed', value: true },
    { label: 'Failed', value: false }
  ]

  showFilterMenu = false
  isSuperAdmin = false

  orderId = ''

  activeName = 'Order'

  filter = {}
  target = null

  async created() {
    const mainUserID = this.$store.state.auth.dataLogin.id
    const selectedUserId = this.$store.state.auth?.accountSelected?.owner
    const selectedAccountId = this.$store.state.auth?.accountSelected?._id
    const accountId = this.$route.query.acid
    if (accountId && selectedAccountId !== accountId) {
      this.handleSignOut()
    }
    const targetOrderId = this.$route.query.targetOrderId
    const sku = this.$route.query.sku
    if (sku) {
      this.activeName = 'Product'
    }

    if (!selectedUserId) {
      this.isShowWarning = false
    } else {
      this.isShowWarning = mainUserID == selectedUserId ? false : true
    }
    // if (!this.isShowWarning) {
      if (!targetOrderId && !sku) {
        this.getSyncErrorsList(0, 'Order')
        this.getSyncErrorsList(0, 'Product')
        this.getSyncErrorsList(0, 'Shipment')
        this.getSyncErrorsList(0, 'Refund')
      }
    // }

    this.isSuperAdmin = await this.$store.state.auth.dataLogin.type

    // await this.getAll()
  }

  async getSyncErrorsList(page = 0, type = 'Order') {
    await this.$store.dispatch('setLoading', true, { root: true })

    let res: Result = {
      total: 0,
      list: [],
      data: []
    }

    if (type === 'Order') {
      // console.log(
      //   `params: {page: ${page}, limit: ${
      //     this.perPage
      //   }, condition: ${JSON.stringify(this.filter)}} `
      // )

      // console.log(`.get: user/${type.toLowerCase()}/sync-error`)

      res = await axios.get(`user/${type.toLowerCase()}/sync-error`, {
        headers: this.headers,
        params: {
          condition: this.filter,
          account: localStorage.getItem('account'),
          page: page === 0 ? page + 1 : page,
          limit: this.perPage
        }
      })

      if (res) {
        // console.log(`order res: ${JSON.stringify(res)}`)

        this.orderSyncErrorList = res.data
        this.totalOrderRows = res.total
      }
    } else if (type === 'Shipment') {
      res = await axios.get(`user/order/sync-shipment-error`, {
        headers: this.headers,
        params: {
          condition: this.filter,
          account: localStorage.getItem('account'),
          page: page === 0 ? page + 1 : page,
          limit: this.perPage
        }
      })

      if (res) {
        this.shipmentSyncErrorList = res.data
        this.totalShipmentRows = res.total
      }
    } else if (type === 'Refund') {
      res = await axios.get(`user/order/sync-refund-error`, {
        headers: this.headers,
        params: {
          condition: this.filter,
          account: localStorage.getItem('account'),
          page: page === 0 ? page + 1 : page,
          limit: this.perPage
        }
      })

      if (res) {
        this.refundSyncErrorList = res.data
        this.totalRefundRows = res.total
      }
    } else {
      // console.log(
      //   `params: {page: ${page}, limit: ${
      //     this.perPage
      //   }, condition: ${JSON.stringify(this.filter)}, connectionTo: ${
      //     this.target
      //   }} `
      // )

      // console.log(`.get: user/${type.toLowerCase()}/sync-error`)

      res = await axios.get(`user/${type.toLowerCase()}/sync-error`, {
        headers: {
          'token-user': localStorage.getItem('tokenUser'),
          account: localStorage.getItem('account'),
          connectionTo: this.target
        },
        params: {
          condition: this.filter,
          account: localStorage.getItem('account'),
          connectionTo: this.target,
          page: page === 0 ? page + 1 : page,
          limit: this.perPage
        }
      })

      if (res) {
        // console.log(`product res: ${JSON.stringify(res)}`)

        this.productSyncErrorList = res.data
        this.totalProductRows = res.total
      }
    }

    await this.$store.dispatch('setLoading', false, { root: true })
  }

  handleCurrentChange(index = 1) {
    let type = this.activeName || 'Product'
    if (type === 'Order') {
      this.currentOrderPage = index
      this.getSyncErrorsList(index, 'Order')
    } else if (type === 'Shipment') {
      this.currentProductPage = index
      this.getSyncErrorsList(index, 'Shipment')
    } else if (type === 'Refund') {
      this.currentProductPage = index
      this.getSyncErrorsList(index, 'Refund')
    } else {
      this.currentProductPage = index
      this.getSyncErrorsList(index, 'Product')
    }
  }
  handleSizeChange(val: number) {
    if (this.activeName === 'Order') {
      // console.log('Size Change Order')
      this.currentOrderPage = 1
      this.perPage = val
      this.getSyncErrorsList(this.currentOrderPage, 'Order')
    } else if (this.activeName === 'Shipment') {
      this.currentShipmentPage = 1
      this.perPage = val
      this.getSyncErrorsList(this.currentOrderPage, 'Shipment')
    } else if (this.activeName === 'Refund') {
      this.currentRefundPage = 1
      this.perPage = val
      this.getSyncErrorsList(this.currentOrderPage, 'Refund')
    } else {
      // console.log('Size Change Product')
      this.currentProductPage = 1
      this.perPage = val
      this.getSyncErrorsList(this.currentProductPage, 'Product')
    }
  }

  /* Filter */
  // Order, Shipment, Refund //
  handleSearchOrder(data: any) {
    this.filter = data.condition
    this.getSyncErrorsList(0, 'Order')
  }
  handleSearchShipment(data: any) {
    this.filter = data.condition
    this.getSyncErrorsList(0, 'Shipment')
  }
  handleSearchRefund(data: any) {
    this.filter = data.condition
    this.getSyncErrorsList(0, 'Refund')
  }

  // Product //
  handleAfter() {
    this.getSyncErrorsList(0, 'Product')
  }
  handleSearchProduct(data: any) {
    this.target = data.target
    this.filter = data.condition
    this.getSyncErrorsList(0, 'Product')
  }
  handleSignOut() {
    localStorage.removeItem('tokenUser')
    localStorage.removeItem('account')
    localStorage.removeItem('isShowChangeAccount')
    localStorage.setItem('redirectPath', '')

    this.$router.push({ name: 'LoginPage' })
    location.reload()
  }
  // changeActiveName() {
  //   this.getSyncErrorsList(0, this.activeName)
  // }
}
